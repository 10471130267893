<template>
    <section class="forms">
        <div class="page-header">
            <h3 class="page-title">
                Add {{ getInfo.pageName }}
            </h3>
            <button type="button" class="btn btn-info believe-btn" @click="goBack()">
                <i class="mdi mdi-keyboard-backspace"></i> Back</button>
        </div>
        <div class="row">
            <div class="col-md-7 m-auto d-flex align-items-stretch grid-margin">
                <div class="row flex-grow">
                    <div class="col-12 grid-margin">
                        <div class="card">
                            <div class="card-body">
                                <b-form @submit.stop.prevent="onSubmit">
                                    <div v-for="(field, key) in getInfo.formDataInsert" :key="field.name">

                                        <b-form-group v-if="field.type == 'input'" id="example-input-group-1"
                                            :label="field.label" :label-for="field.label">

                                            <b-form-input :id="field.name" :name="field.name" :ref="field.name"
                                                :placeholder="field.placeholder" v-model="field.value"
                                                v-validate="getValidationRules(key)" :state="validateState(field.name)"
                                                :aria-describedby="field.name" :data-vv-as="field.name">
                                            </b-form-input>

                                            <b-form-invalid-feedback :id="field.name">{{
                                                veeErrors.first(field.name)
                                            }}</b-form-invalid-feedback>

                                        </b-form-group>


                                        <b-form-group v-if="field.type == 'file-image' || field.type == 'file-pdf'" id="example-input-group-1"
                                            :label="field.label" :label-for="field.label">

                                            <!-- v-validate="getValidationRules(key)"
                                                :state="validateState(field.name)"  -->

                                            <b-form-file :id="field.name" :name="field.name" :ref="field.name"
                                                @change="validateFileExtension" 
                                                :v-validate="field.type == 'file-image' ? getValidationRules(key,'image') : getValidationRules(key,'pdf')"
                                                :state="validateState(field.name)" :placeholder="field.placeholder"
                                                v-model="field.value" :aria-describedby="field.name"
                                                :data-vv-as="field.name">
                                            </b-form-file>

                                            <b-form-invalid-feedback :id="field.name">{{
                                                veeErrors.first(field.name)
                                            }}</b-form-invalid-feedback>

                                            <small class="error-msg">{{ customErrors[field.name] }}</small>

                                        </b-form-group>


                                        <b-form-group v-if="field.type == 'textarea'" id="example-input-group-1"
                                            :label="field.label" :label-for="field.label">

                                            <b-form-textarea @keydown.native="test_keydown_handler(field.name)"
                                                :ref="field.name" :id="field.name" :name="field.name"
                                                :placeholder="field.placeholder" v-model="field.value"
                                                v-validate="getValidationRules(key)" :state="validateState(field.name)"
                                                :aria-describedby="field.name" :data-vv-as="field.name" rows="3"
                                                max-rows="6">
                                            </b-form-textarea>


                                            <b-form-invalid-feedback :id="field.name">{{
                                                veeErrors.first(field.name)
                                            }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </div>
                                    <b-button type="submit" variant="success" class="btn-block believe-btn">
                                        Submit
                                    </b-button>
                                </b-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    name: "Add",
    // components: {
    //     DynamicTable, Pagination, Search
    // },
    data() {
        return {
            customErrors: {}
        }
    },
    computed: {
        getInfo() {
            console.log(this.$store.getters.getInfo);
            return this.$store.getters.getInfo;
        }
    },
    methods: {
        ...mapActions(['submitForm']),

        validateFileExtension(event) {
            const fileInput = event.target; // Get the file input element
            const selectedFile = fileInput.files[0];
            // console.log(selectedFile.name);
            // console.log(selectedFile.type);
            // console.log(selectedFile.size);

            if (!selectedFile.type) {
                return false;
            }
            const allowedExtensions = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'];
            const result = allowedExtensions.includes(selectedFile.type);
            if (!result) {
                let objKey = event.target.name;
                let objValue = "The image must be a file of type: jpeg, jpg, png, gif";
                this.customErrors[objKey] = objValue;
            } else {
                delete this.customErrors[event.target.name];
            }
        },

        getValidationRules(key) {
            console.log('get validation rules ::::: ', key);
            return this.getInfo.formDataInsert[key].validationRules;
        },

        test_keydown_handler(event, id) {
            if (event.which === 13) {
                let txt = document.getElementById(id);
                let indexOfChar = txt.value.slice(0, txt.selectionStart).length + 1
                txt.value = txt.value.slice(0, txt.selectionStart) + '\r\n' +
                    txt.value.slice(txt.selectionStart, txt.value.length);
                this.setCaretPosition(id, indexOfChar);
            }
        },

        validateState(ref) {
            if (
                this.veeFields[ref] &&
                (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },

        goBack() {
            console.log(this.getInfo?.pageUrl);
            this.$router.push({ path: this.getInfo?.pageUrl });
        },

        onSubmit() {
            if (Object.keys(this.customErrors).length > 0)
                return;
            this.$validator.validateAll().then(result => {
                if (!result) {
                    return;
                }
                this.submitForm().then(() => {
                    this.$router.push({ path: this.getInfo?.pageUrl });
                });
            });
        }
    },
};
</script>

<style>
.error-msg {
    color: #dc3545;
}
</style>

